class QExportDataClass {
   constructor() {}

   // HANLDE
   transformFormatDataToExport(keyToExports) {
      // console.log(keyToExports)

      let getDataTotransform = []
      let getKeyObject = []

      keyToExports.map((item, index) => {
         getKeyObject = Object.keys(keyToExports[index])
      })

      console.log('keyToExports', keyToExports)

      getDataTotransform = keyToExports.map((element, index) => {
         return {
            [`${getKeyObject[index]}`] : 'Joks'
         }
      })

   

      return keyToExports;
   }
}

export default new QExportDataClass();
