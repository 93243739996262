

// Data to export - Invoice
const facturationProvider = (data) => {
    let ToExportData = []
    ToExportData = data.map((el, index) => {
        return {
           'numéro facture': el.code,
           'objet': el.libelle,
           'Nom et prenoms': `${el.client.nom + ' ' + el.client.prenoms}`,
           'Montant TTC': el.total_ttc,
           'Montant TVA': el.total_taxe === 0 ? 0 :  (parseInt(el.total_ttc) * el.total_taxe)/100,
           'Montant REMISE': el.remise === null ? 0 : parseInt(el.total_ttc) - (1 - (el.remise/100) )
        }
     })

     return ToExportData
}

// Data to export - Quotes
const DevisProvider = (data) => {
    let ToExportData = []

    
    ToExportData = data.map((el, index) => {
        return {
           'numéro facture': el.code,
           'objet': el.libelle,
           'Nom et prenoms': `${el.client.nom + ' ' + el.client.prenoms}`,
           'Montant TTC': el.total_ttc,
           'Montant TVA': el.total_taxe === null ? 0 :  (parseInt(el.total_ttc) * el.total_taxe)/100,
           'Montant REMISE': el.remise === null ? 0 : parseInt(el.total_ttc) - (1 - (el.remise/100)),
           'Statut' : el.status_devis
        }
     })

     return ToExportData
}

// Data to export - Relaunch
const RelaunchProvider = (data) => {
    let ToExportData = []
    
    ToExportData = data.map((el, index) => {
        return {
         'Numero facture' : el.code,
         'objet': el.libelle,
         'Nom et prenoms': `${el.nom + ' ' + el.prenoms}`,
         'Montant TTC' : el.total_ttc,
         'Montant HT': el.total_ht,
         'Montant TVA': el.total_taxe === null ? 0 :  (parseInt(el.total_ttc) * el.total_taxe)/100,
        'Montant REMISE': el.remise === null ? 0 : parseInt(el.total_ttc) - (1 - (el.remise/100) )
        }
     })

     return ToExportData
}


// Data to export - Relaunch
const ClientProviders = (data) => {
    let ToExportData = []
    
    ToExportData = data.map((el, index) => {
        return {
         'Nom et prenoms' :`${el.nom + ' ' + el.prenoms}`,
         'Email': el.email,
         'Localisation' : el.localisation.formatted_address,
         'type de client': el.status_user
        }
     })

     return ToExportData
}


// Data to export - Relaunch
const ProductProviders = (data) => {
    let ToExportData = []
    
    ToExportData = data.map((el, index) => {
        return {
         'Numero articles' : el.code,
         'Type (produits/services)': `${el.type}`,
         'Categorie' : el.categorie.libelle,
         'Nom du produit': el.libelle,
         'prix d\'achat': el.prix_achat,
         'prix de vente': el.prix_vente,
         'Quantité en stock': el.quantite,
         'Description': el.description
        }
     })

     return ToExportData
}

export {
    facturationProvider,
    DevisProvider,
    RelaunchProvider,
    ClientProviders,
    ProductProviders
}