<template>
   <div v-if="data.length !== 0" class="ml-[8px] ">
      <!-- Filter the Transactions by type -->
      <b-dropdown text="Types" variant="outline-secondary">
         <template #button-content>
            <span> Exporter </span>
            <!-- </div> -->
         </template>
         <b-dropdown-item
            v-for="type in typeExport"
            :key="type.label"
            @click="qTypeExport(type.label)"
         >
            <div class="flex items-center">
               <feather-icon :icon="type.icon" class="mr-50" />
               <span>{{ type.label }}</span>
            </div>
         </b-dropdown-item>
      </b-dropdown>

      <b-modal
         id="modal-export-to-pdf"
         cancel-variant="outline-secondary"
         ok-title="qDownload"
         cancel-title="Annuler"
         centered
         title="Telecharge le pdf"
         @ok="qDownload"
         modal-class="modal-fullscreen"
         class=""
         size="xl"
      >
         <b-table
            hover
            responsive
            primary-key="id"
            :items="state.dataToExport"
            :fields="state.dataKey"
            show-empty
            empty-text="Aucun article enregistré"
            class="bg-white text-left main_invoice_cards"
         >
         </b-table>

         <template #modal-footer>
            <b-button
               :disabled="state.loading === true ? true : false"
               variant="primary"
               @click.stop.prevent="qDownload"
            >
               <span v-if="state.loading === false">Download</span>
               <b-spinner
                  v-if="state.loading === true"
                  label="Spinning"
               ></b-spinner>
            </b-button>
         </template>
      </b-modal>
   </div>
</template>

<script>
import {
   watch,
   onMounted,
   onUpdated,
   onBeforeMount,
   computed,
   reactive,
   ref,
} from '@vue/composition-api';
import exportFromJSON from 'export-from-json';
import useAppConfig from '@core/app-config/useAppConfig';
import URL from '@/views/pages/request';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import { BRow, BCol, BModal, BButton, VBModal } from 'bootstrap-vue';
export default {
   props: ['xtype', 'data'],

   components: {
      BRow,
      BCol,
      BModal,
      BButton,
      VBModal,
   },

   setup(props, { root }) {
      const state = reactive({
         loading: false,
         dataKey: [],
         dataToExport: [],
      });

      const typeExport = reactive([
         {
            label: 'CSV',
            icon: 'CardIcon',
         },
         {
            label: 'XLS',
            icon: 'CardIcon',
         },
         {
            label: 'JSON',
            icon: 'CardIcon',
         },
         {
            label: 'TXT',
            icon: 'CardIcon',
         },
         {
            label: 'PDF',
            icon: 'CardIcon',
         },
      ]);

      const qTypeExport = (type) => {
         const data = props.data;

         state.dataKey = Object.keys(data[0]);
         state.dataToExport = data;

         if (type === 'PDF') {
            root.$bvModal.show('modal-export-to-pdf');
         } else {
            const fileName = 'ediqia_export_data_' + `${props.xtype}`;
            const exportType = type.toLowerCase();
            exportFromJSON({ data, fileName, exportType });
         }
      };

      const qDownload = () => {
         state.loading = true;

      setTimeout(() => {
            var opt = {
            margin: [0, 0, 0, 0],
            padding: 4,
            filename: `${props.status === 'devis' ? 'devis_N' : 'facture_N'}${
               props.idInvoice
            }.pdf`,
            image: { type: 'pdf', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'A4', orientation: 'landscape' },
            enableLinks: true,
         };

         // New Promise-based usage:
         const getElemntInvoicePrint = document.querySelector(
            '.main_invoice_cards'
         );
         html2pdf()
            .from(getElemntInvoicePrint)
            .set(opt)
            .save()

            // Hide Modal download pdf
         state.loading = false;
         root.$bvModal.hide('modal-export-to-pdf');
      }, 100);

         
      };

      return {
         typeExport,
         qTypeExport,
         state,
         qDownload,
      };
   },
};
</script>
<style scoped>
.test-modal .modal-dialog {
   max-width: 100%;
   margin: 0;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   height: 100vh;
   display: flex;
   position: fixed;
   z-index: 100000;
}</style
>>
